header {
  background-color: #FFF;
}

.wrapper-header__system {
  justify-content: space-between;
  background-color: #FFF;
  padding: 16px 20px;
  align-items: center;
  margin: 0 auto;
  font-family: 'Inter UI', sans-serif!important;

  .header-logo {
    display: block;
    position: relative;
    width: 150px;
    height: auto;
    margin-left: 0;
    margin-right: 0%;
    line-height:0;
  }
}

.wrapper-header__system, .header-menu ul {
  display: flex;
  color: #000;

  a {
    text-decoration: none;
    color: #000;
  }

  a:hover {
    text-decoration: underline!important;
  }

}

.header-title {
  margin-left: auto;
  margin-right: auto;
  align-self: center;

  a:hover {
    text-decoration: underline;
  }
}

.header-name {
  font-size: 21px;
  font-weight: 400;
}

.wrapper-header a:hover {
  text-decoration: none;
}

.header-menu {
  font-size: 17px;

  li {
    padding-left: 1.5em;
  }
}

.section-heading {
  background-color: #F2F2F2;
  padding: 10px 20px;
}

@media (max-width: 890px) {
  .wrapper-header__system {
      .header-logo {
      margin-right: 10%;
    }

    .header-title {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 769px) {
  .wrapper-header__system {
    padding: 16px 12px;
  }
}


@media (max-width: 640px) {

  .wrapper-header__system {
    display: inherit;
    padding: 12px 18px 4px;

    .header-name {
      font-size: 20px;
    }

    .header-title {
      margin-bottom: 5px;
    }

    .header-logo {
      margin-right: 0;
      margin-bottom: 15px;
      width: 110px;
    }

    .header-menu {

      li:first-child {
        padding-left: 0;
      }
    }
  }

}
