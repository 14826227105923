// Pattern: help

.help-wrapper {
  background-color: $white;
  box-shadow: 0 8px 17px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.188235);
  width: 360px;
  top: 85px;
  left: 480px;
  position: absolute;
  visibility: hidden;

  &:target {
    visibility: visible;
    opacity: 1;
  }
}

.help-title {
  background-color: $border-colour;
  padding: 1rem 1.5rem;
  display: flex;

  .govuk-heading-s {
    margin-top: 5px;
    margin-bottom: 0;
  }

  .menu {
    font-size: 14px;
    display: flex;
    margin-top: 9px;
    margin-left: 70px;
    cursor: default;

    li {
      padding-left: 10px;
      justify-content: flex-end;
    }

    a {
      color: $black;
      text-decoration: none;
    }
  }

}

.help {
  font-size: 16px;
  padding: 1.5rem;

  p {
    margin-top: 1.5rem;
    margin-bottom: 0;
  }

  details {
    border-bottom: 1px solid $panel-colour;
    margin-bottom: 10px;
  }
}


@media (min-width: 641px) and (max-width: 916px) {
  .help-wrapper {
    left: 370px;
  }

}

@media (max-width: 790px) {
  .help-wrapper {
    display: none;
  }

}
