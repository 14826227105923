.app-navigation {
  $navigation-height: 50px;
  padding-right: govuk-spacing(3);
  padding-left: 40px;
  background-color: #FFF;
  @include govuk-font(19, $weight: 400);

  @include govuk-media-query($until: tablet) {
    display: none;
  }

  li.current-page {
    border-bottom: 3px solid $govuk-text-colour !important;
    font-weight: bold;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;

    & > li {
      box-sizing: border-box;
      height: $navigation-height;
      float: left;
      line-height: $navigation-height;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;

      &:hover {
        border-bottom: 3px solid $govuk-text-colour;
      }

      &.app-navigation--current-page {
        border-bottom: 3px solid $govuk-text-colour !important;

        a:hover {
          color: lighten($govuk-text-colour, 10%);
        }
      }

      a {
        display: block;
        padding: 0 govuk-spacing(3);
        color: $govuk-text-colour!important;
        text-decoration: none;

        &:visited {
          color: $govuk-text-colour!important;
        }

        &:hover,
        &:focus {
          color: $govuk-text-colour;
          background-color: inherit;
        }
      }
    }
  }
}

@media (max-width: 768px) {

  .app-navigation {
    padding-left: 2px;

    &__list {
      & > li {
        a {
          padding: 0 10px;
        }
      }
    }
  }


}

@media (max-width: 640px) {

  .app-navigation {
    padding-left: 9px;
  }


}
