.navbar .navbar__list-items {
  padding-left: 0;

  li {
    display: inline-block;
    margin-bottom: 0;
    a {
      @include govuk-font(19, $weight: regular);
      @extend .bold-small;
      color: $govuk-text-colour;
      display: inline-block;
      padding: 15px 10px 10px 10px;
      margin-right: 15px;
      margin-bottom: 0;
      font-size: inherit;
      text-decoration: none;
      &:hover {
        color: $govuk-text-colour;
        border-bottom: 3px solid $govuk-text-colour !important;
      }
      &:focus {
        outline: 0;
      }
    }
    &.active a,
    &.active a:hover {
    color: $govuk-text-colour;
    border-bottom: 3px solid $govuk-text-colour !important;
    font-weight: bold;
    }
  }
}
