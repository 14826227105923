.govuk-table {

  th {
    font-weight: 400;
  }

  .govuk-table__header {
    font-weight: 700;
  }

}
