// This is a GOV.UK Publishing specific component that
// can be seen at http://govuk-static.herokuapp.com/component-guide/related_items

.app-related-items {
  border-top: 10px solid govuk-colour("blue");
  padding-top: govuk-spacing(2);
}

.app-related-items .govuk-list > li {
  margin-bottom: govuk-spacing(2);
}
