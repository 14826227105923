// Pattern: flash cards
// Problem: interupt the user with important information

.circle-white {
  color: #005ea5;
  background: #fff;
  float: left;
  margin-right: 15px;
}

.circle-step {
  min-width: 24px;
  min-height: 24px;
  line-height: 24px;
}

.circle {
    margin-top: 6px;
    display: inline-block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    font-family: "ntatabularnumbers", "nta", Arial, sans-serif;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
}

.flash-card {
  margin-top: 1em;
  padding: 30px;
  background: $link-colour;
  color: $page-colour;

  header {
    background-color: inherit;
  }

  p {
    margin-left: 32px;
  }

  ul.how-to-renew {
    padding-left: 0;
    list-style: none;

    h2 {
      margin: 0;
      margin-bottom: 0;
      padding: 0;
      color: white;
      font-size: 28px;
    }

    li {
      margin-bottom: 22px;
    }
  }

  h1 {
    color: white;
    &:first-of-type {
      margin-top: 0 !important;
    }
  }

  a {
    color: $page-colour;
    &:visited, &:hover, &:active {
      color: $page-colour; }
    }
    details {
      // margin-bottom: 1em;
      summary {
        color: $page-colour;
      }
      .panel {
        border-left-color: $page-colour;
      }
    }
  }

  @media (min-width: 769px) {
    .flash-card {
      margin-top: 1.25em;
      padding: 35px 50px;
    }
  }
