// Pattern: side tab

ul.tab__nav {
  @include govuk-font(19);
  margin-top: 0;
  padding-left: 0;

  li {
    margin-bottom: 0;
    list-style: none;
  }
  li a {
    padding: 10px 5px 15px;
    text-decoration: none;
    display: block;

    &.active, &:hover.active {
      @include govuk-font(19, $weight: bold);
      color: $black;
    }
  }

  a:visited {
    color: $link-colour;
  }

  .active:hover {
    background: $white;
    text-decoration: none;
  }

  li a:hover {
    color: $link-colour;
    text-decoration: underline;
    background: $grey-3;

    &.locked {
      color: $black;
      background-color: transparent;
      cursor: default;
    }
  }
  li a:focus {
    color: $black;
  }
  .locked {
    color: $grey-1;
    padding: 10px 5px 15px;
  }
  hr {
    height: 1px;
    background-color: $border-colour;
    margin: 0;
  }

  @media screen and (max-width: 640px ) {
    margin-bottom: 15px;
  }

}

.tab__content {
  float: left;
  width: 100%;
}
