$govuk-dialog-width: 1024px;

  .govuk-modal-dialog,
  .govuk-modal-dialog__backdrop {
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  // Hide dialog when closed
  .govuk-modal-dialog {
    display: none;
  }

  // Show dialog when opened
  .govuk-modal-dialog--open {
    display: table;
    display: flex;
    flex-direction: column;
  }

  // Wrapper to handle overflow scrolling
  .govuk-modal-dialog__wrapper {
    box-sizing: border-box;
    display: table-cell;
    display: flex;
    height: inherit;
    @include govuk-responsive-padding(7, "top");
    @include govuk-responsive-padding(7, "bottom");
    overflow-y: auto;
    vertical-align: middle;
    align-items: center;
  }

  // HTML5 dialog component
  .govuk-modal-dialog__box {
    box-sizing: border-box;
    display: block;
    position: relative;
    z-index: 1;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    padding: 0;
    border: $govuk-focus-width solid govuk-colour("black");
    background: govuk-colour("white");

    // Add focus outline to dialog
    &:focus {
      outline: $govuk-focus-width solid $govuk-focus-colour;
    }

    // Hide browser backdrop
    &::backdrop {
      display: none;
    }
  }

  // Header with close button
  .govuk-modal-dialog__header {
    @include govuk-clearfix;
    @include govuk-responsive-margin(5, "bottom");
    color: govuk-colour("black");
    background-color: #f5f5f5;
    text-align: right;
  }

  // Inner content
  .govuk-modal-dialog__content {
    @include govuk-responsive-padding(6);
    padding-top: 0;
  }

  .govuk-modal-dialog__content p {
    max-width: 100%;
  }

  // Custom backdrop
  .govuk-modal-dialog__backdrop {
    opacity: .8;
    background: govuk-colour("black");
    pointer-events: none;
    touch-action: none;
  }

  // Crown icon
  .govuk-modal-dialog__icon {
    display: block;
    margin: 6px 0 0 6px;
    @include govuk-responsive-margin(5, "left");
    float: left;
  }

  // Close button
  .govuk-modal-dialog__close {
    $font-size: 36px;
    $line-height: 1;

    display: block;
    width: auto;
    min-width: 44px;
    margin: 0;
    padding: 2px 5px;
    float: right;
    //color: govuk-colour("black");
    //background-color: rgb(219,218,217);
    box-shadow: none !important;
    font-size: $font-size;
    @if $govuk-typography-use-rem {
      font-size: govuk-px-to-rem($font-size);
    }
    @include govuk-typography-weight-bold;
    line-height: $line-height;

    &:hover {
      color: govuk-colour("black");
      background-color: govuk-colour("yellow");
    }

    &:active {
      top: 0;
    }
  }

  // New dialog width, inline button + link
  @include govuk-media-query($from: tablet) {
    .govuk-modal-dialog__content {
      padding-top: 0;
    }

    .govuk-modal-dialog__box {
      width: percentage($govuk-dialog-width / map-get($govuk-breakpoints, desktop));
    }
  }

  // Fixed width
  @include govuk-media-query($from: desktop) {
    .govuk-modal-dialog__box {
      width: $govuk-dialog-width;
    }
  }