.button[disabled] {
	box-shadow: 0 0px 0 #003618;
}

.button-white {
  box-shadow: 0 1px 0 $text-colour;
  border: $text-colour 1px solid;
	background-color: white;
	color: $text-colour;
	&[disabled]{
		background-color: white;
	}
}

.button-white:hover {
	background-color: darken(white, 5%);
	color: $text-colour;
	&[disabled]{
		background-color: white;
	}
}

.button-white:focus {
	background-color: darken(white, 5%);
	color: $text-colour;
	&[disabled]{
		background-color: white;
	}
}

.button-secondary {
	background-color: $panel-colour;
  box-shadow: 0 1px 0 $text-colour;
	color: $text-colour;
  border: $text-colour 1px solid;
  &[disabled]:active {
		background-color: $border-colour;
    box-shadow: 0 0px 0 $black;
  }
}

.button-secondary:hover {
	background-color: darken($panel-colour, 5%);
	color: $text-colour;
}

.button-secondary:focus {
	background-color: darken($panel-colour, 5%);
	color: $text-colour;
}

.button-affordance {
	background-color: $panel-colour;
	color: $text-colour;
  box-shadow: 0 1px 0 $text-colour;
  border: $black 1px solid;
  span[class^="hod-"] {
      &::before {
  		color: #555D5E;
  	}
  }
  &[disabled]:active {
		background-color: $panel-colour;
    box-shadow: 0 0px 0 $text-colour;
  }
}

.button-affordance:hover {
	background-color: darken($panel-colour, 5%);
	color: $text-colour;
}

.button-affordance:focus {
	background-color: darken($panel-colour, 5%);
	color: $text-colour;
}
