.pagination .pagination_list-items {

  li {
    display: inline-block;
    a {
      //@include govuk-font(19, $weight: bold);
      @extend .bold-small;
      color: $govuk-blue;
      display: inline-block;
      padding: 15px 5px 10px 5px;
      margin-right: 15px;
      text-decoration: none;

      &:focus {
        outline: 0;
      }
    }
    &.active a,
    &.active a:hover {
      color: $govuk-blue;
      -webkit-box-shadow: inset 0px -5px 0px 0px $govuk-blue;
      -moz-box-shadow: inset 0px -5px 0px 0px $govuk-blue;
      box-shadow: inset 0px -5px 0px 0px $govuk-blue;
    }
  }
}

.padding {
  padding-left: 15px;
}

.pagination {
  padding: 0;
}
.pagination__item {
  display: inline-block;
  list-style: none;
}
.pagination__link {
  background-color: transparent;
  cursor: pointer;
  display: block;
  border: none;
  padding: 5px 10px;
  text-decoration: none;
  font-family: "Inter UI", sans-serif;
  font-size: 18px;
  //border: 1px solid $border-colour;

  &:hover, &:focus {
    background: $highlight-colour;
    outline: 3px solid $focus-colour;
    color: #2b8cc4;
  }

  &.current {
    color: $text-colour;
    font-weight: 700;
    border: none;
    pointer-events: none;
    cursor: default;

    &:hover, &:focus {
      color: $text-colour;
      background: none;
    }
  }
}

.pagination__summary {
  font-family: 'Inter UI', sans-serif;
  -webkit-font-smoothing: antialiased;
  padding: 8px 0  ;

  @media (min-width: 642px) {
    float: right;
  }
}
