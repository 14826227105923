.app-cookie-banner {
  @include govuk-font(16);
  @include govuk-text-colour;

  display: flex;
  align-items: center;

  box-sizing: border-box;
  width: 100%;

  padding: govuk-spacing(3) govuk-spacing(3) govuk-spacing(3) govuk-spacing(3);

  background-color: lighten(desaturate(govuk-colour("light-blue"), 8.46), 42.55);
}

.app-cookie-banner__message {
  margin: 0;
  @include govuk-width-container;
}

.app-cookie-banner__button {
  border: none;
  padding: govuk-spacing(1) govuk-spacing(1) govuk-spacing(1) govuk-spacing(1);
  background-color: lighten(desaturate(govuk-colour("light-blue"), 8.46), 42.55);
  cursor: pointer;
}

@include govuk-media-query($media-type: print) {
  .app-cookie-banner {
    display: none !important;
  }
}
