.wrapper-footer__system {
  padding: 30px 54px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  align-items: center;
  max-width: inherit;

  .footer-message {

    p {
      margin-bottom: 0;
      font-size: 14px;
    }
  }

  .footer-menu {
    align-items: flex-end;
    display: flex;
  }

  .footer-menu ul {
    display: flex;

    li {
      padding-left: 1.5em;
      list-style: none;
    }

    li a {
      color: #3B3B3B;
    }

    li:first-child {
      padding-left: 0;
    }
  }
}

footer {
  border-top: 1px $border-grey solid;
  background-color: #FFF;
}

@media (max-width: 768px) {
  .wrapper-footer__system {
    padding: 30px 14px;
    display: inherit;

    .footer-menu {
      margin-top: 10px;
    }
  }


}

@media (max-width: 690px) {

  .wrapper-footer__system {
    padding: 30px 20px;
    font-size: 13px;

    .footer-message {

      p {
        font-size: 13px;
      }
    }

    .footer-menu {
      margin-top: 8px;
    }

    .footer-menu ul {
      padding-left: 0;
    }

  }

}
