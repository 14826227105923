.timeline {
  padding: calc(2 * #{5px});
  margin: 0 auto;

  .action_needed {
    background-color: $panel-colour;
    padding: 5px 20px 5px 15px;

    h2 {
      font-size: 1.2em;
    }

    &:before {
      content: "";
      display: block;
      width: calc(2 * 5px);
      height: calc(2 * 5px);
      border-radius: 50%;
      background-color: #6e777a;
      border: 8px solid #6f777b;
      position: absolute;
      z-index: 2;
      left: -35px;
      top: 16px;
    }
  }

  ul {
    list-style-type: none;
    position: relative;
    padding: 0 0 0 10px;
    margin: 0 0 20px 0;
    &:after {
      content: "";
      position: absolute;
      top: 45px;
      left: -4.5px;
      bottom: 0;
      border-left: 4px solid #6f777b;
      height: 96%;
    }

    li {
      margin: 4px 0 20px 10px;
      padding-left: 15px;
      position: relative;
      display: block;
      border-bottom: 1px solid $panel-colour;
      &:last-child {
        margin-bottom: 0px;
      }

      .timeline-reference {
        @extend .govuk-heading-s;
        display: table;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: -20px;
          width: 20px;
          top: 9px;
          z-index: -1;
        }
      }

      &:before {
        content: "";
        display: block;
        width: calc(2 * #{5px});
        height: calc(2 * #{5px});
        border-radius: 50%;
        background-color: white;
        border: 4px solid $grey-1;
        position: absolute;
        z-index: 2;
        left: -31px;
        top: 0px;
      }

      h2 {
        @extend .govuk-heading-m;
        margin-top: 0.5em;
        font-size: 0.9em;
      }
    }
  }
}

.timeline-details {
  margin: 10px 0;

  summary {
    font-size: 0.9em;
  }

  span {
    display: block;
    font-size: 0.8em;
    padding-bottom: 5px;
    padding-left: 10px;
    border-left: 4px solid $govuk-blue;
  }
}

.time {
  color: #555;
  font-size: 16px;
}
