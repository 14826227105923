// Highlight which row is being hovered
.table-clickable tbody tr:hover {
  background-color: $highlight-colour;
  cursor: pointer;
}

// Set selected color
$selected-colour: #FFECC7;

// Highlight which row is selected
.table-clickable tbody tr.checked {
  background-color: $selected-colour;
}

// Highlight which row is hovered when selected
.table-clickable tbody tr.checked:hover {
  background-color: darken($selected-colour, 5%);
}

.table-clickable {

  .multiple-choice--hod {
  padding: 0.9em 0 0 1.05263em;
  margin: 0;

  }
}
