.status {
  background-color: $govuk-blue;
  color: $white;
  padding: 20px;

  p {
    font-weight: 700;
    color: $white;
  }

  .status-extra {
    font-weight: normal;
  }

  .govuk-button {
    color: $text-colour;
    margin-bottom: 0;
  }
}

@include govuk-media-query($from: tablet) {
  .status {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 24px;
      margin: 0;
    }

    .status-extra {
      font-size: 18px;
    }
  }
}
