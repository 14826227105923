.panel {
  box-sizing: border-box;
  clear: both;
  border-left-style: solid;
  border-color: #bfc1c3;
  padding: 0.2em 0.7em;
  margin-bottom: 0.78947em;

  h2.govuk-heading-s {
    font-size: 22px;
    margin-bottom: 0.6em;
    margin-top: 0.6em;
  }
}

.panel-border-wide {
  border-left-width: 10px;
}

.panel-border-wide.alert-default {
  border-color: #2b8cc4;
  background-color: #dbeff9;
}

.panel-border-wide.alert-success {
  border-color: #28a197;
  background-color: #c6ece9;
}

.panel-border-wide.alert-error {
  border-color: #c42b2b;
  background-color: #f3dede;
}

.panel-border-wide.alert-warning {
  border-color: #FFDD00;
  background-color: #FFF7BF;
}
