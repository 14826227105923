.multiple-choice--hod {
  .multiple-choice {
    padding-top: 1px;
    padding-bottom: 2px;
  }
  .govuk-checkboxes__label {
    padding: 3px 2px 5px;
  }

  .govuk-checkboxes__item {
    margin-bottom: 0;
  }
}

.multiple-choice--hod [type=checkbox] + label::before {
  width: 25px;
  height: 25px;
}

.multiple-choice--hod [type=checkbox] + label::after {
  border-width: 0 0 3px 3px;
  width: 11px;
  height: 4px;
  top: 8px;
  left: 6px;
}

@media screen and (max-width: 640px) {
  .multiple-choice--hod {
    .multiple-choice {
      margin-bottom: 15px;
      padding-top: 4px;
    }
  }
  .govuk-checkboxes__label {
    padding: 7px 6px 3px;
  }
}
