@include govuk-exports("app-subnav") {

  .app-subnav {
    padding: govuk-spacing(6) govuk-spacing(3) govuk-spacing(3) 42px;
    @include govuk-font(16);

    @include govuk-media-query($until: tablet) {
      display: none;
    }
  }

  .app-subnav__section {
    margin: 0 0 govuk-spacing(4);
    padding: 0;
    list-style-type: none;

    .app-subnav__section-item--current {
      border-left-color: #005ea5;
      font-weight: bold;
    }
  }

  .app-subnav__link {
    display: block;
    padding: 8px govuk-spacing(6) 8px govuk-spacing(2);
    border-left: 3px solid transparent;
    text-decoration: none;

    &:focus {
      background: inherit;
    }

    &:hover,
    &:active,
    &:visited {
      color: $govuk-link-colour;
    }

    &:hover {
      border-left-color: $govuk-link-hover-colour;
    }
  }

  .app-subnav__section-item--current .app-subnav__link {
    border-left-color: $govuk-link-colour;
    font-weight: bold;
  }

  .app-subnav__section--nested {
    margin-bottom: 0;
  }

  .app-subnav__section--nested .app-subnav__link {
    padding-left: govuk-spacing(4);
    font-weight: 400;
  }

  .app-subnav__theme {
    margin: 0;
    padding: govuk-spacing(2) govuk-spacing(3);
    color: govuk-colour("grey-1");
    @include govuk-font(19);
  }
}

@media (max-width: 768px) {

  .app-subnav {
    padding: 20px 10px 15px 0;
  }

}

@media (max-width: 640px) {

  .app-subnav {
    padding: 20px 10px 0 7px;
  }

}
