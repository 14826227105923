.highlight-summary {
  background: #f4f4f4;
  padding: 10px;
  margin-bottom: 25px;

  ul {
    margin: 0;
  }
}

.highlight {
  border-bottom: 3px solid #ffbf47;
  margin-right: 1px;
  background-color: #fff2d3;
  padding: 2px 0px 0px;
}
