.card {
  display: block;
  float: left;
  margin-bottom: 30px;
}

.card__body {
  display: block;
  background-color: $panel-colour;
  padding: 1em;
  color: $text-colour;
  text-decoration: none;

  span {
    display: block;
  }
}

a.card__body {
  color: $link-colour;

  &:hover, &:focus {
    cursor: pointer;
    background-color: $highlight-colour;
    color: $link-hover-colour;
    text-decoration: none;
  }
}

.card__footer {
  display: block;
  background-color: $highlight-colour;
  padding: 1em;
}

// GOVUK cards
.govuk-card {
    border-bottom: 3px solid $purple;
    box-sizing: border-box;
}

.govuk-card--contained {
    background-color: #FFF;
    padding-bottom: 30px;
}

.govuk-card--contained .govuk-card__content {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 10px;

    .govuk-heading-s {
      font-size: 22px;
      margin-top:20px;
    }
}

@media screen and (max-width: 640px) {

  .govuk-card--contained {
    margin-top: 20px;
  }

}
