.case-title {
  font-size: 38px;
  font-weight: bold;
}

.case-ref {
  font-size: 28px;
  color: $secondary-text-colour;
  margin-top: -5px;
  margin-bottom: 5px;
}

.alert {
  font-size: 15px;
}

.case-table {

  .govuk-heading-m {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  table td {
    font-size: 17px;
  }
}
