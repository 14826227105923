.multiple-choice--hod [type=radio] + label::before {
  width: 28px;
  height: 28px;
}

.multiple-choice--hod [type=radio] + label::after {
  border: 7px solid;
  top: 7px;
  left: 7px;
}


.multiple-choice--hod {
  .multiple-choice {
    padding-top: 1px;
    padding-bottom: 2px;
  }
  .govuk-radios__label {
    padding: 3px 8px 5px;
  }

  .govuk-radios__item {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 640px) {
  .multiple-choice--hod {
    .multiple-choice {
      margin-bottom: 15px;
      padding-top: 4px;
    }
  }
  .govuk-radios__label {
    padding: 7px 6px 3px;
  }
}
