.app-header-mobile-nav-toggler-wrapper {
    display: block;
    float: right;
  }

.app-header-mobile-nav-toggler {
  display: none;
  min-height: 40px; // match the height of the search box
  padding-right: 0;
  padding-left: 0;
  border: 1px solid transparent;
  background-color: govuk-colour("white");
  box-shadow: 0 0 0 govuk-colour("white");
  color: #005ea5!important;

  &:active,
  &:focus,
  &:hover {
    background-color: $base-grey-medium; // Override the button default green
    box-shadow: 0 0 0 $base-grey-medium; // Override the button default green
  }

  &::after {
    @include govuk-shape-arrow($direction: down, $base: 10px, $display: inline-block);
    content: "";
    margin-left: govuk-spacing(1);
    border-color: govuk-colour("blue");
  }

  // toggle arroww on active
  .app-header-mobile-nav-toggler-active {
    &::after {
      @include govuk-shape-arrow($direction: up, $base: 10px, $display: inline-block);
    }
  }

  .js-enabled & {
    @include govuk-media-query($until: tablet) {
      display: inline;
    }
  }
}

.app-mobile-nav {
  display: none;

  &--active {
    display: block;

    @include govuk-media-query($from: tablet) {
      display: none;
    }
  }
}

.no-js .app-mobile-nav {
  @include govuk-media-query($until: tablet) {
    display: block;
  }
}

.app-mobile-nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: $app-light-grey;


  & > li {
    @include govuk-typography-common;
    @include govuk-typography-weight-bold;
    font-size: 19px; // We don't have a font mixin that produces 19px on mobile
    font-size: govuk-px-to-rem(19px); // sass-lint:disable-line no-duplicate-properties

    & > a {
      @include govuk-typography-weight-bold; // Override .govuk-link weight
      display: block;
      padding: 16px govuk-spacing(4) 17px govuk-spacing(4);
      text-decoration: none;

      &:hover,
      &:focus,
      &:visited {
        color: govuk-colour("blue");
        background-color: inherit;
      }
    }
  }
}

.app-mobile-nav__subnav {
  display: none;
  margin: 0;
  padding: govuk-spacing(2) 0 0 0;
  list-style: none;
  border-top: 1px solid govuk-colour("grey-3");
  border-bottom: 1px solid govuk-colour("grey-3");
  background-color: govuk-colour("white");

  & > li {
    & > a {
      @include govuk-font(19);
      display: block;
      padding: govuk-spacing(2) govuk-spacing(4);

      text-decoration: none;
    }

    &.current-page a {
      border-left: 4px solid govuk-colour("blue");
    }
  }
}

.js-enabled .app-mobile-nav__subnav--active {
  display: block;
}

.app-mobile-nav__current-page {
  border-left: 4px solid govuk-colour("blue");
}

.app-mobile-nav__theme {
  margin: 0;
  padding: govuk-spacing(4) govuk-spacing(4) govuk-spacing(1) govuk-spacing(4);
  color: govuk-colour("grey-1");
  // Font is defined as a hard 19px so
  // it doesn't re-size on mobile viewport
  font-size: 19px;
  font-size: govuk-px-to-rem(19px); // sass-lint:disable-line no-duplicate-properties
  font-weight: 400;
}

.app-mobile-nav__theme-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: govuk-colour("white");

  & > li {
    @include govuk-font(19);

    & > a {
      display: block;
      padding: govuk-spacing(3) govuk-spacing(4) govuk-spacing(2) govuk-spacing(4);

      text-decoration: none;
    }

    &.current-page a {
      padding-left: govuk-spacing(4) - 4px;
      border-left: 4px solid govuk-colour("blue");
    }

    &:last-child a {
      padding-bottom: govuk-spacing(5);
    }
  }
}

.js-enabled .app-mobile-nav__subnav-toggler--active {
  border-top: 1px solid govuk-colour("white");
}

.invert-arrow {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%)!important;
}
