@mixin label ($label-background: $panel-colour, $label-border: $secondary-text-colour, $label-text: $text-colour) {
    background: $label-background;
    color: $white;
    /*font-size: 17px;*/
    display: inline-block;
    line-height: 1;
    padding: 4px 5px 3px 5px;
    margin: 0 calc(2 * #{5px}) 5px 10px;
    text-align: left;
    text-decoration: none;
    font-size: 1rem;
    font-weight: normal;
}
.label {
    @include label(#5c6367, $text-colour);
}
.label--white {
    @include label($page-colour);
    color: $text-colour;
    border: 1px solid $border-colour;
}
.label--blue {
    @include label($link-colour, $link-colour);
}

.label--red {
    @include label($red, $red);
}

@media (max-width: 641px) {
  .label, .label--white, .label--blue, .label--red {
    font-size: .85rem;
  }
}
